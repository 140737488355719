import * as React from 'react';
import { PageProps } from 'gatsby';
import { PageLayout as Layout } from '../components/Layouts/PageLayout/PageLayout';
import { Seo } from '../components/Seo/Seo';

import { PageTitle } from '../components/ui/pageTitle/pageTitle';

import PageContentHandler from '../components/PageContentHandler';
import pageTrainingData from './data/pageTraining.json';
import pageTrainingPreviewData from './data/pageTrainingPreview.json';
import { VideoOverlay } from 'src/components/ui/videoOverlay/videoOverlay';

// markup
const TrainingPage: React.FC<PageProps> = ({ location }) => {
  // states
  const [videoOverlayVisible, setVideoOverlayVisible] = React.useState(true);

  /* How to get correctly formatted URL parameters
    const params = new URLSearchParams();
    params.append('overlay', 'https://youtu.be/djSKp_pwmOA');
    params.toString();
  */
  const [overlayVideoLink, setOverlayVideoLink] = React.useState<string | null>(
    null
  );
  React.useEffect(() => {
    if (!window?.location?.search == null) return;

    const params = new URLSearchParams(window.location.search);

    if (!params.has('overlay')) return;
    setOverlayVideoLink(params.get('overlay'));
  }, []);

  if (!location.hostname) return null;

  const pageData =
    location.hostname === 'gatsby.node.tvg.la' ||
    location.hostname === 'localhost'
      ? pageTrainingPreviewData[0]
      : pageTrainingData[0];

  if (!pageData) return null;

  return (
    <Layout className="training bg bg-training after-header-spacer">
      <Seo title="Training" />
      <PageTitle title="Training" />
      <PageContentHandler pageData={pageData} />

      {overlayVideoLink ? (
        <VideoOverlay
          title="Training Video"
          url={overlayVideoLink}
          open={videoOverlayVisible}
          setOpen={setVideoOverlayVisible}
        />
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default TrainingPage;
